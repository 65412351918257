<template>
  <div>
    <v-row no-gutters class="py-3" style="background-color: white">
      <v-col cols="12" md="12" class="mb-2">
        <h3 class="text-center">동산 테이블</h3>
      </v-col>

      <v-col cols="12">
        <v-simple-table style="border: 1px solid #eeeeee">
          <template v-slot:default>
            <thead>
              <tr>
                <th width="20%" class="text-center">호수</th>
                <th width="30%" class="text-center">상품명</th>
                <th width="25%" class="text-center">대출액</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(value, key) in dongsanMap">
                <tr :key="key" :class="{ selected: value.open }">
                  <td
                    colspan="3"
                    class="text-center"
                    @click="openDongsan(value)"
                  >
                    {{ value.title }} (<span class="font-weight-black">{{
                      value.root
                    }}</span
                    >) - 모채권: {{ key }} 호
                    <v-icon>
                      {{ value.open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </td>
                </tr>

                <template v-if="value.open">
                  <tr
                    v-for="child in value.children"
                    :key="`${child.idx}-child`"
                    :class="{ selected: value.open }"
                  >
                    <td class="text-center">{{ child.idx }} 호</td>
                    <td class="text-center">
                      <v-progress-circular
                        v-if="isLoading"
                        indeterminate
                        size="20"
                        color="grey"
                      />
                      <span v-else>
                        {{ child.title }}
                      </span>
                    </td>
                    <td class="text-center">
                      <v-progress-circular
                        v-if="isLoading"
                        indeterminate
                        size="20"
                        color="grey"
                      />
                      <span v-else>
                        {{ child.totalAmount | commaFormat }}
                      </span>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      // 앞으로 새로 생길일 없으니 하드코딩
      brown: {
        // 브라운 로지스 시작
        5906: {
          root: '브라운로지스',
          title: '동산39호',
          parent: 5906,
          children: [{ idx: 5906 }, { idx: 5947 }, { idx: 6037 }],
          open: false,
        },
        5901: {
          root: '브라운로지스',
          title: '동산45호',
          parent: 5901,
          children: [
            { idx: 5901 },
            { idx: 5902 },
            { idx: 5903 },
            { idx: 5904 },
          ],
          open: false,
        },
        6383: {
          root: '브라운로지스',
          title: '동산51호',
          parent: 6383,
          children: [{ idx: 6383 }, { idx: 6443 }],
          open: false,
        },
        6087: {
          root: '브라운로지스',
          title: '동산52호',
          parent: 6087,
          children: [
            { idx: 6087 },
            { idx: 6146 },
            { idx: 6195 },
            { idx: 6444 },
            { idx: 6445 },
          ],
          open: false,
        },
        6315: {
          root: '브라운로지스',
          title: '동산53호',
          parent: 6315,
          children: [
            { idx: 6315 },
            { idx: 7081 },
            { idx: 7205 },
            { idx: 7435 },
            { idx: 7744 },
            { idx: 7891 },
            { idx: 8143 },
            { idx: 8217 },
          ],
          open: false,
        },
        6363: {
          root: '브라운로지스',
          title: '동산54호',
          parent: 6363,
          children: [
            { idx: 6363 },
            { idx: 7085 },
            { idx: 7206 },
            { idx: 7805 },
          ],
          open: false,
        },
        6446: {
          root: '브라운로지스',
          title: '동산56호',
          parent: 6446,
          children: [
            { idx: 6446 },
            { idx: 6447 },
            { idx: 6448 },
            { idx: 7223 },
            { idx: 7436 },
            { idx: 7945 },
            { idx: 8216 },
            { idx: 8337 },
            { idx: 8359 },
            { idx: 8436 },
          ],
          open: false,
        },
        6481: {
          root: '브라운로지스',
          title: '동산57호',
          parent: 6481,
          children: [
            { idx: 6481 },
            { idx: 6482 },
            { idx: 6483 },
            { idx: 7498 },
            { idx: 7499 },
          ],
          open: false,
        },
        6573: {
          root: '브라운로지스',
          title: '동산59호',
          parent: 6573,
          children: [
            { idx: 6573 },
            { idx: 6574 },
            { idx: 6575 },
            { idx: 7309 },
            { idx: 7594 },
            { idx: 7745 },
            { idx: 7892 },
            { idx: 7964 },
            { idx: 8218 },
          ],
          open: false,
        },
        6576: {
          root: '브라운로지스',
          title: '동산60호',
          parent: 6576,
          children: [
            { idx: 6576 },
            { idx: 6577 },
            { idx: 6578 },
            { idx: 7430 },
            { idx: 7592 },
            { idx: 7743 },
            { idx: 7870 },
            { idx: 7894 },
            { idx: 7943 },
            { idx: 8288 },
          ],
          open: false,
        },
        // 브라운 로지스 끝
      },
      pure: {
        // 퓨어나인 동산 66호
        6914: {
          root: '퓨어나인',
          title: '동산66호',
          parent: 6914,
          children: [{ idx: 6914 }, { idx: 7224 }, { idx: 7804 }],
          open: false,
        },
      },
      seojin: {
        // 서진 - 디즈니가방 동산 44호
        5821: {
          root: '서진',
          title: '디즈니가방 동산44호',
          parent: 5821,
          children: [{ idx: 5821 }, { idx: 5822 }],
          open: false,
        },
      },
      victory: {
        // 승리코퍼레이션 - e동산 의류
        7913: {
          root: '승리코퍼레이션',
          title: 'e동산 의류',
          parent: 7913,
          children: [{ idx: 7913 }, { idx: 7914 }, { idx: 7915 }],
          open: false,
        },
      },
      urth: {
        // URTH
        6765: {
          root: 'URTH',
          title: 'URTH',
          parent: 6765,
          children: [
            { idx: 6765 },
            { idx: 6766 },
            { idx: 6767 },
            { idx: 6768 },
            { idx: 6769 },
            { idx: 6770 },
            { idx: 6771 },
            { idx: 6772 },
            { idx: 8625 },
            { idx: 8711 },
            { idx: 8833 },
            { idx: 9632 },
          ],
          open: false,
        },
      },
      smartKorea: {
        // 스마트코리아 동산 70호
        7171: {
          root: '스마트코리아',
          title: '동산70호',
          parent: 7171,
          children: [{ idx: 7171 }, { idx: 7431 }, { idx: 7890 }],
          open: false,
        },
      },
    }
  },
  computed: {
    dongsanMap() {
      return {
        ...this.brown,
        ...this.pure,
        ...this.seojin,
        ...this.victory,
        ...this.urth,
        ...this.smartKorea,
      }
    },
  },
  methods: {
    async openDongsan(selected) {
      const changeTo = !selected.open
      if (changeTo === true) {
        for (const key in this.dongsanMap) {
          const dongsan = this.dongsanMap[key]
          dongsan.open = false
        }

        selected.open = true
        await this.getFundingData(selected)

        this.$emit('update:selectDongsan', selected)
      } else {
        selected.open = false
        this.$emit('update:selectDongsan', null)
      }
    },
    async getFundingData(selected) {
      const children = selected.children

      this.isLoading = true
      for (const child of children) {
        if (!child?.title) {
          const idx = child.idx
          const q = `
            data: getFundingData(fundingDataIdx: ${idx}) {
              idx
              title
              totalAmount
            }
          `

          const data = await this.$fundaApi.query(gql`{${q}}`)
          child.title = data.title
          child.totalAmount = data.totalAmount
        }
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
tr.selected {
  background-color: #eeeeee;
}
</style>
